import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import pRetry from "p-retry";
import React, { forwardRef, useCallback, useEffect } from "react";

import { useNavigateToWorkspace } from "@/router";
import { ApolloClient, useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/react";
import {
  Link,
  LinkProps,
  MakeGenerics,
  Navigate,
  Outlet,
  ReactLocation,
  Router,
  createBrowserHistory,
  useMatchRoute,
  useNavigate,
  useSearch,
} from "@tanstack/react-location";

import {
  ConnectionsQuery,
  CurrentUserRoleDocument,
  EltSyncQuery,
  FindOneSyncQuery,
  GetAccountIdDocument,
  GetAccountIdQuery,
  GetAccountIdQueryResult,
  ListModelsQuery,
  UserQuery,
  useCurrentWeldPlanLazyQuery,
  useGetAccountSlugQuery,
} from "./apollo/types";
import LoadingSpinner from "./components/elements/LoadingSpinner";
import { SidebarProvider } from "./components/elements/Sidebar";
import { WeldLoader } from "./components/elements/WeldLoader";
import Center from "./components/elements/layout/Center";
import Container from "./components/elements/layout/Container";
import ContentContainer from "./components/elements/layout/ContentContainer";
import { WorkspaceBanners } from "./components/modules/AppBanners";
import { AppUnknownExceptionViewRenderer } from "./components/modules/Error/AppUnknownExceptionView";
import {
  FreemiumTrialEndedDialog,
  useFreemiumTrialEndedDialogDisclosure,
} from "./components/modules/FreemiumTrialEndedAppBlocker";
import { KBar } from "./components/modules/KBar/KBar";
import MobileHeader from "./components/modules/MobileHeader";
import TitleManager from "./components/modules/TitleManager";
import { NewDataSourceSlideOverContainer } from "./components/modules/new-data-source-slideover";
import { WorkspaceSidebar } from "./components/modules/sidebar/WorkspaceSidebar";
import { ViewDataSourcSlideOverContainer } from "./components/modules/view-data-source-slideover";
import { CheckoutModalRoot } from "./features/billing";
import { AutoShowingEssentialPlanDiscontinuationDialog } from "./features/essential-plan";
import { DatawarehouseMigrationProvider } from "./features/migration";
import { ActiveMigrationOverlay } from "./features/migration/ActiveMigrationOverlay";
import { NPSSurveyDialogContainer } from "./features/nps";
import { useTrackStonlyEvents } from "./features/product-guide";
import { useTrialStartedListener } from "./features/subscription/useTrialStartedListener";
import { useStartIntercomForUser } from "./hooks/useIntercom";
import { MOBILE_SIZE_VIEWPORT_THRESHOLD } from "./hooks/useIsMobileSizeViewport";
import useTrackSessionStarted from "./hooks/useTrackSessionStarted";
import { IntegrationsProvider, OAuthCallbackPage } from "./integrations";
import AcceptInvitationPage from "./pages/AcceptInvitationPage";
import { dataSourcesRoute } from "./pages/EltSyncs/routes";
import { CoreMetricsDrawer } from "./pages/Metrics/CoreMetricsDrawer";
import { useFocusMode } from "./pages/ModelTool/hooks/useFocusMode";
import { AiAssistantSidebarProvider } from "./pages/ModelTool/model-generator/assistant";
import { editorRoute } from "./pages/ModelTool/routes";
import { workflowRoute } from "./pages/Orchestration/routes";
import { exportsRoute } from "./pages/ReverseEltSyncs/routes";
import { AddOnsCheckoutModalRoot } from "./pages/Settings/Billing/Checkout";
import { PaymentMethodSetupResultPage } from "./pages/Settings/Billing/PaymentMethodSetupResultPage";
import { settingsRoute } from "./pages/Settings/routes";
import setupRoutes from "./pages/Setup/routes";
import { workspacesRoute } from "./pages/WorkspacesPage/routes";
import { DataWarehouseProvider } from "./providers/DataWarehouseProvider";
import { useToast } from "./providers/ToastProvider";
import { useRefetchUserData, useUserAccounts } from "./providers/UserProvider";
import {
  AccountValidator,
  RouteAwareAccountProvider,
  useCurrentAccount,
  useMostRecentAccountId,
  useResetMostRecentAccountId,
} from "./providers/account";
import * as routeHelpers from "./router/routeHelpers";
import { SocketProvider } from "./socket/SocketContext";
import { createStorageKey } from "./utils/storage";

dayjs.extend(LocalizedFormat);

export type LocationGenerics = MakeGenerics<{
  Params: {
    slug: string;
    modelId: string;
    draftId: string;
    dashboardId: string;
    folderName: string;
    syncId: string;
    integrationId: string;
    connectionId: string;
    userId: string;
    accountId: string;
    workflowId: string;
    workflowRunId: string;
  };
  LoaderData: {
    findOneSync: FindOneSyncQuery["findOneSync"];
    findOneEltSync: EltSyncQuery["eltSync"];
    connections: ConnectionsQuery["connections"];
    queries: ListModelsQuery["models"];
    user: UserQuery["user"];
    accountId: string;
  };
  Search: {
    workspace: string;
    integrationId: string;
    invite: string;
    workflowRunId: string;
    slug: string;
    utm_source: string;
    tileId: string;
    dashboardId: string;
    productTour: boolean;
    viewDataSource: string;
    returnTo: string;
  };
  RouteMeta: {
    breadcrumb: (
      params: LocationGenerics["Params"],
    ) => string | React.ReactElement;
  };
}>;

export const history = createBrowserHistory();
const location = new ReactLocation<LocationGenerics>({ history });

const Main = (props: { children: JSX.Element }) => {
  const accounts = useUserAccounts();
  const matchRoute = useMatchRoute();
  // User is in setup flow?
  const isOnSetupRoute = matchRoute({ to: "/setup", fuzzy: true });
  // Users who received an invitation will b  e redirected to the `/workspaces` route
  const isOnWorkspacesRoute = matchRoute({ to: "/workspaces", fuzzy: true });
  const isOnInvitationCallbackRoute = matchRoute({
    to: "/_/invitation-callback",
    fuzzy: true,
  });

  useTrackStonlyEvents();

  if (
    accounts.length === 0 &&
    !isOnSetupRoute &&
    !isOnWorkspacesRoute &&
    !isOnInvitationCallbackRoute
  ) {
    return <Navigate to="/setup" replace search />;
  }

  return props.children;
};

const RedirectToMostRecentAccount = () => {
  const navigate = useNavigate();
  const navigateToWorkspace = useNavigateToWorkspace();

  const mostRecentAccountId = useMostRecentAccountId();
  const resetMostRecentAccountId = useResetMostRecentAccountId();

  const { data } = useGetAccountSlugQuery({
    skip: mostRecentAccountId == null,
    variables: {
      id: mostRecentAccountId ?? "",
    },
    onError: () => {
      resetMostRecentAccountId();
      navigate({ to: routeHelpers.workspacesRoute() });
    },
  });

  useEffect(() => {
    if (mostRecentAccountId == null) {
      navigate({ to: routeHelpers.workspacesRoute() });
      return;
    }
    const slug = data?.accountById.slug;
    if (slug !== undefined) {
      navigateToWorkspace.bySlug(slug);
    }
  }, [data, mostRecentAccountId, navigate, navigateToWorkspace]);

  return null;
};

function RedirectToSources() {
  const account = useCurrentAccount();

  return <Navigate to={`/${account.slug}/sources`} replace search />;
}

export async function getAccountContext(
  slug: string,
  client: ApolloClient<object>,
) {
  const accountId =
    (
      await client.query<GetAccountIdQueryResult["data"]>({
        query: GetAccountIdDocument,
        variables: { slug },
      })
    ).data?.accountBySlug.id ?? "";
  return { accountId };
}

export default function Index() {
  const client = useApolloClient();
  return (
    <Router<LocationGenerics>
      defaultLoaderMaxAge={0}
      defaultLinkPreloadMaxAge={0}
      defaultPendingMs={400}
      defaultPendingMinMs={700}
      defaultPendingElement={<PendingElement />}
      location={location}
      routes={[
        ...setupRoutes,
        {
          path: "signup",
          element: <Navigate to="/" replace search />,
        },
        {
          path: "_",
          children: [
            {
              path: "oauth-callback",
              element: <OAuthCallbackPage />,
            },
            {
              path: "invitation-callback",
              element: <AcceptInvitationPage />,
            },
            {
              path: "payment-method-setup-callback",
              element: <PaymentMethodSetupResultPage />,
            },
          ],
        },
        workspacesRoute(client),
        {
          path: ":slug",
          element: (
            <RouteAwareAccountProvider paramName="slug">
              <AccountValidator>
                <IntegrationsProvider>
                  <DataWarehouseProvider>
                    <SocketProvider>
                      <DatawarehouseMigrationProvider>
                        <WorkspaceDashboard />
                      </DatawarehouseMigrationProvider>
                    </SocketProvider>
                  </DataWarehouseProvider>
                </IntegrationsProvider>
              </AccountValidator>
            </RouteAwareAccountProvider>
          ),
          loader: async ({ params }) => {
            const { data } = await client.query<GetAccountIdQuery>({
              query: GetAccountIdDocument,
              variables: { slug: params.slug },
            });
            const accountId = data.accountBySlug.id;
            await client.query({
              query: CurrentUserRoleDocument,
              context: { accountId },
            });
            return {
              accountId,
            };
          },
          pendingElement: (
            <Center className="fixed inset-0 z-50 bg-gray-100 dark:bg-background">
              <WeldLoader />
            </Center>
          ),
          children: [
            {
              path: "gather", //Added for routing people to home screen if they have bookmarked old gather path
              element: <Navigate to="/" />,
            },
            {
              path: "extract", //Added for routing people to home screen if they have bookmarked old path
              element: <Navigate to="/" />,
            },
            {
              path: "activate", //Added for routing people to home screen if they have bookmarked old path
              element: <Navigate to="/" />,
            },
            {
              path: "exports", //Added for routing people to home screen if they have bookmarked old path
              element: <Navigate to="/" />,
            },
            editorRoute(client),
            {
              element: <DefaultWorkspaceLayout />,
              children: [
                dataSourcesRoute(client),
                workflowRoute(client),
                exportsRoute(client),
                settingsRoute(client),
                {
                  element: <RedirectToSources />,
                },
              ],
            },
          ],
        },
        {
          element: <RedirectToMostRecentAccount />,
        },
      ]}
    >
      <Main>
        <Outlet />
      </Main>
    </Router>
  );
}

const sidebarStateAtom = atomWithStorage<boolean>(
  createStorageKey("sidebar-state"),
  false,
);

const WorkspaceDashboard = () => {
  const [defaultSidebarCollapseState, setSidebarCollapseState] =
    useAtom(sidebarStateAtom);

  useTrackSessionStarted();

  useStartIntercomForUser(true);

  const toast = useToast();
  const refetchUserData = useRefetchUserData();
  const [fetchCurrentWeldPlan] = useCurrentWeldPlanLazyQuery();

  useTrialStartedListener({
    onTrialStarted(trialEndDate) {
      refetchUserData();
      fetchCurrentWeldPlan({ fetchPolicy: "network-only" });
      toast(
        "Trial started",
        "Your trial will end on " + dayjs(trialEndDate).format("LL"),
        "info",
      );
    },
  });

  const matchRoute = useMatchRoute();
  // The sidebar should be collapsed initially on the editor route
  const isOnEditorRoute =
    matchRoute({ to: "/:slug/editor", fuzzy: true }) !== undefined;

  return (
    <KBar>
      <SidebarProvider
        isCollapsed={isOnEditorRoute || defaultSidebarCollapseState}
        breakpoint={MOBILE_SIZE_VIEWPORT_THRESHOLD}
        onCollapseChange={setSidebarCollapseState}
      >
        <AiAssistantSidebarProvider>
          <Outlet />
          <WorkspaceSlideOvers />
        </AiAssistantSidebarProvider>
      </SidebarProvider>
    </KBar>
  );
};

function WorkspaceSlideOvers() {
  const { viewDataSource } = useSearch<LocationGenerics>();
  return (
    <>
      <NewDataSourceSlideOverContainer />
      <ViewDataSourcSlideOverContainer
        show={typeof viewDataSource === "string"}
        syncId={viewDataSource}
      />
      <CheckoutModalRoot />
      <AddOnsCheckoutModalRoot />
      <NPSSurveyDialogContainer />
      <CoreMetricsDrawer />
    </>
  );
}

function DefaultWorkspaceLayout() {
  const [isFocusMode] = useFocusMode();
  const freemiumTrialExpiredDisclosure =
    useFreemiumTrialEndedDialogDisclosure();
  return (
    <div className="absolute inset-0 flex flex-col">
      <WorkspaceBanners />
      <div className="relative grow">
        <Container>
          <TitleManager />
          {!isFocusMode && <WorkspaceSidebar />}
          <ContentContainer>
            <MobileHeader />
            <Sentry.ErrorBoundary fallback={AppUnknownExceptionViewRenderer}>
              <ActiveMigrationOverlay />
              <Outlet />
            </Sentry.ErrorBoundary>
          </ContentContainer>
        </Container>
        <FreemiumTrialEndedDialog {...freemiumTrialExpiredDisclosure} />
        <AutoShowingEssentialPlanDiscontinuationDialog />
      </div>
    </div>
  );
}

const PendingElement = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-gray-100 dark:bg-background">
      <LoadingSpinner />
    </div>
  );
};

/**
 * Retry promise (dynamic import of js chunk) a few times on failure with exponential backoff.
 * Catches final failure in the `retryErrorHandler` below which will attempt to refresh the page.
 * @param fn promise to retry
 * @returns promise
 */
export async function retryRoute<T>(fn: () => Promise<T>) {
  return pRetry(fn, {
    retries: 4,
  }).catch((error) => {
    return retryErrorHandler(error);
  });
}

let isReloadingPage = false;

function retryErrorHandler(error: any) {
  if (isReloadingPage) {
    return;
  }
  if (error instanceof Error && error.name === "ChunkLoadError") {
    // A new version of the app has been released and the active bundle is referencing outdated js chunks.
    // A refresh is needed.
    const chunkRequestUrl = (error as any).request as string;
    if (!chunkRequestUrl) {
      return;
    }
    try {
      // Use local storage to make sure we don't end up with an infinite reload loop
      const storageKey = "force-reload-chunk";
      const wasForceReloaded =
        localStorage.getItem(storageKey) === chunkRequestUrl;
      if (!wasForceReloaded) {
        localStorage.setItem(storageKey, chunkRequestUrl);
        isReloadingPage = true;
        window.location.reload();
        return;
      } else {
        Sentry.captureException(error, {
          extra: {
            wasForceReloaded: true,
            chunkUrl: chunkRequestUrl,
          },
        });
        return;
      }
    } catch {
      // local storage failed, do nothing.
    }
  }
  return Promise.reject(error);
}

export function useNavigateWithSlug() {
  const account = useCurrentAccount();
  const navigate = useNavigate();

  const slug = account?.slug;

  return useCallback(
    (
      useNavigateParams: Parameters<ReturnType<typeof useNavigate>>[0],
      withSlug: boolean = true,
    ) => {
      const prependSlug = (to: string, slug: string) => {
        return `/${slug}${to.startsWith("/") ? to : `/${to}`}`;
      };
      navigate({
        ...useNavigateParams,
        to:
          withSlug && slug && typeof useNavigateParams.to === "string"
            ? prependSlug(useNavigateParams.to, slug)
            : useNavigateParams.to,
      });
    },
    [navigate, slug],
  );
}

export const LinkWithSlug = forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => {
    const { slug } = useCurrentAccount();

    const prependSlug = (to: string) => {
      return `/${slug}${to.startsWith("/") ? to : `/${to}`}`;
    };

    return (
      <Link
        _ref={ref}
        {...props}
        to={typeof props.to === "string" ? prependSlug(props.to) : props.to}
      />
    );
  },
);
